<template>
 
  <div class="">

    <!-- ======================== Sambutan ========================  -->
    <div class="q-my-xxs q-py-xl q-px-xl bg-gray-3" v-if="$q.screen.lt.sm">
      <div class="row justify-between">
      <div class="text-h6 text-weight-bolder text-black">Artikel Ilmiah</div>
      <div class="text-h6" style="font-size: 12px;">Beranda / <span class="text-secondary">Artikel Ilmiah</span></div>
      </div>
    </div>

    <div class="q-my-xxs q-py-xl q-px-xl text-center bg-grey-3" v-if="$q.screen.sm || $q.screen.md || $q.screen.lg || $q.screen.xl">
      <div class="row justify-between">
      <div class="text-h6 text-weight-bolder text-black">Artikel Ilmiah</div>
      <div class="text-h6" style="font-size: 12px;">Beranda / <span class="text-secondary">Artikel Ilmiah</span></div>
      </div>
    </div>

<div class="row wrap justify-between items-start content-start ">
      <div class="col-12 col-md-12 col-sm-12 col-xs-12 q-pa-md q-gutter-y-lg items-end">
            <div class="col-12 q-pa-lg">
                <q-input standout="bg-secondary text-black" bottom-slots v-model="cari_value" @keyup="cari_data()" label="Pencarian" style="width:100%" >
                    <template v-slot:prepend>
                        <q-icon color="black" name="search" />
                    </template>
                </q-input>
            </div>
        
        <div class="col-12 q-pa-lg">
          <q-card v-for=" data in list_data " :key="data.id" class="my-card q-pa-lg" flat bordered clickable  >
            
            <q-card-section v-if="$q.screen.lt.sm">
                <q-card-section>
                  <div class="text-label text-blue-grey" style="font-size:11px">       
                    <q-icon  :size="'xs'" name="mdi-pencil" /> {{data.penulis}} <br>
                  </div> 
                  <div class="text-uppercase" style="font-size:15px;"><b>{{data.judul}}</b></div>
                <br>
                <span v-html="data.isi" style="text-align:justify;"></span>
              </q-card-section> 
              <q-card-actions vertical class="justify-around q-px-md">
          <q-btn size="35px" round color="secondary" icon="download" @click="persistent = true, selectData(data)">
            <q-tooltip class="bg-secondary">Unduh Artikel Ilmiah</q-tooltip>
          </q-btn>
        </q-card-actions>
            </q-card-section>

            <q-card-section horizontal v-if="$q.screen.sm || $q.screen.md || $q.screen.lg || $q.screen.xl">
              <q-card-section class="col">
                <div class="text-uppercase" style="font-size:15px;"><b>{{data.judul}}</b></div>
                <div class="text-label text-secondary" style="font-size:11px">       
                  <q-icon  :size="'xs'" name="mdi-pencil" /> {{data.penulis}} <br>
                </div>
                <br>
                <span v-html="data.isi" style="text-align:justify;"></span>
              </q-card-section> 
              <q-card-actions vertical class="justify-around q-px-md">
          <q-btn size="35px" round color="secondary" icon="download" @click="persistent = true, selectData(data)">
            <q-tooltip class="bg-secondary">Unduh Artikel Ilmiah</q-tooltip>
          </q-btn>
        </q-card-actions>
            </q-card-section>
          </q-card>
        </div>

          <div class="q-pa-lg flex flex-center">
            <q-btn @click="btn_prev" color="secondary" icon="mdi-skip-previous" />
            &nbsp;
            <span style="font-size: 85%; color: #a5a5a5">
                <b>{{page_first}} - {{page_last}}</b>
            </span>
            &nbsp;
            <q-btn @click="btn_next" color="secondary" icon="mdi-skip-next" />   
          </div>
      </div>
    </div>
    <q-dialog v-model="persistent" persistent full-width transition-show="scale" transition-hide="scale">
      <q-card class="text-white">
        <q-card-section class="bg-teal">
          <div class="text-h6">Artikel Ilmiah</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
                        <div class="text-center">
                                        <embed :src="$store.state.FILE_PATH+form.file" width="1250" height="500" type='application/pdf'>
                                    </div>
        </q-card-section>

        <q-card-actions align="right" class="bg-white text-teal">
          <q-btn flat label="Tutup" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>     
</template>

<script>
export default {
  name: 'artikel',
  data() {
        return {
            data_load : false,
            form : {
                id : '',                
                judul : '',
                isi : '',
                file : '',
            },

            list_data: [],
            page_first: 1,
            page_last: 0,
			cari_value: "",
      persistent : false,
        }
    },
    methods: {
    getView: function() {
      fetch(this.$store.state.URL_ARTIKEL + "view", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          data_ke: this.page_first,
          cari_value: this.cari_value,
          judul: this.judul
        })
      })
        .then(res => res.json())
        .then(res_data => {
            this.list_data = res_data.data;
            this.page_last = res_data.jml_data;
        });
    },

    selectData: function(data) {
        this.form = {
            id : data.id,
			judul : data.judul,
			isi : data.isi,
            file : data.file,
        };
    },

    mdl_exit: function() {
      this.judul = "";
      this.isi = "";
      this.file = "";
      this.file_old = "";
    },

  // ================== PAGINASI ====================
    alertku: function(type, title) {
      this.$swal({
        type: type,
        title: title,
        showConfirmButton: false,
        timer: 800
      });
    },

    btn_prev : function(){
        if(this.page_first>1){
            this.page_first--
        }else{
            this.page_first = 1;
        }
        this.getView();
    },

    btn_next : function(){
        
        if(this.page_first >= this.page_last){
            this.page_first == this.page_last
        }else{
            this.page_first++;
        }
        this.getView();
    },

    cari_data : function(){

        this.page_first = 1;
        this.getView();
    },
  // ================== PAGINASI ====================
  },
  // =============================================================== METHODS =========================================================================

  mounted() {

        this.getView();

    }
}
</script>
