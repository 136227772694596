<template>
  <q-layout view="hHh lpR fFf">
      <headernya />

    <q-page-container>
      <router-view />
    </q-page-container>

    <q-footer elevated class="bg-grey-8 text-white">
      <q-toolbar>
        <q-toolbar-title>
          <!-- <q-avatar>
            <img src="https://cdn.quasar.dev/logo-v2/svg/logo-mono-white.svg">
          </q-avatar> -->
          <div style="text-align:center; font-size:12px;">Copyright : Diskominfo & BRIDA Kab. Konawe Selatan</div>
        </q-toolbar-title>
      </q-toolbar>
    </q-footer>

  </q-layout>
</template>

<script>
import { ref } from 'vue'
import headernya from './components/header.vue'

export default {
  name: 'LayoutDefault',

  components: {
    headernya

  },

  setup () {
    return {
      leftDrawerOpen: ref(false)
    }
  }
}
</script>
