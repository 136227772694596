<template>
 
  <div class="">
    <!-- ======================== Sambutan ========================  -->
    <div class="q-my-xxs q-py-xl q-px-xl bg-gray-3" v-if="$q.screen.lt.sm">
      <div class="row justify-between">
      <div class="text-h6 text-weight-bolder text-black">Publikasi</div>
      <div class="text-h6" style="font-size: 12px;">Beranda / <span class="text-secondary">Publikasi</span></div>
      </div>
    </div>

    <div class="q-my-xxs q-py-xl q-px-xl text-center bg-grey-3" v-if="$q.screen.sm || $q.screen.md || $q.screen.lg || $q.screen.xl">
      <div class="row justify-between">
      <div class="text-h6 text-weight-bolder text-black">Publikasi</div>
      <div class="text-h6" style="font-size: 12px;">Beranda / <span class="text-secondary">Publikasi</span></div>
      </div>
    </div>

    <div class="row wrap justify-between items-start content-start ">
      <div class="col-12 col-md-12 col-sm-12 col-xs-12 q-pa-md q-gutter-y-lg items-end">
            <div class="col-12 q-pa-lg">
                <q-input standout="bg-secondary text-black" bottom-slots v-model="cari_value" @keyup="cari_data()" label="Pencarian" style="width:100%" >
                    <template v-slot:prepend>
                        <q-icon color="black" name="search" />
                    </template>
                </q-input>
            </div>
        
        <div class="col-12 q-pa-lg">
          <div class="tbl_responsive">
          <!-- =================================================== KONTENT =========================================================== -->
            <table width="100%">
              <tr class="h_table_head bg-teal-2">
                <th width="5%" class="text-center">No</th>
                <th width="15%">Kategori</th>
                <th width="15%">Penulis</th>
                <th width="15%">Tujuan</th>
                <th width="15%">Judul</th>
                <th width="5%">File</th>
              </tr>
              <tr class="h_table_body" v-for="(data, index ) in list_data" :key="data.id">
                <td class="text-center">{{index+1}}</td>
                <td >
                                                {{data.kategori_id}}
                                            </td>
                <td >
                                                {{data.nama}}
                                            </td>
                                            <td >
                                                {{data.tujuan}}
                                            </td>
                                            <td >
                                                {{data.judul}}
                                            </td>
                <td class="text-center">
                  <q-btn round color="secondary" icon="download" @click="persistent = true, selectData(data)">
            <q-tooltip class="bg-secondary">Unduh File Lampiran</q-tooltip>
          </q-btn>
                 

                </td>
              </tr>

            </table>

          <!-- =================================================== KONTENT =========================================================== -->
        </div>
        </div>

          <div class="q-pa-lg flex flex-center">
            <q-btn @click="btn_prev" color="secondary" icon="mdi-skip-previous" />
            &nbsp;
            <span style="font-size: 85%; color: #a5a5a5">
                <b>{{page_first}} - {{page_last}}</b>
            </span>
            &nbsp;
            <q-btn @click="btn_next" color="secondary" icon="mdi-skip-next" />   
          </div>
      </div>
    </div>
    <q-dialog v-model="persistent" persistent full-width transition-show="scale" transition-hide="scale">
      <q-card class="text-white">
        <q-card-section class="bg-teal">
          <div class="text-h6">Lampiran</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
                        <div class="text-center">
                                        <embed :src="$store.state.FILE_PATH+form.file" width="1250" height="500" type='application/pdf'>
                                    </div>
        </q-card-section>

        <q-card-actions align="right" class="bg-white text-teal">
          <q-btn flat label="Tutup" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>

  </div>     
</template>

<script>
export default {
  name: 'publikasi',
  data() {
         return {
      data_load: false,
      form: {
        id: "",
        judul: "",
        nama: "",
        kategori_id: "",
        file: "",
      },
      persistent : false,

      list_data: [],
      page_first: 1,
      page_last: 0,
      cari_value: "",
    };
    },
    methods: {
    getView: function () {
      fetch(this.$store.state.URL_PUBLIKASI + "view", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          data_ke: this.page_first,
          cari_value: this.cari_value,
        }),
      })
        .then((res) => res.json())
        .then((res_data) => {
          this.list_data = res_data.data;
          this.page_last = res_data.jml_data;
          console.log(res_data);
        });
    },

    selectData: function (data) {
      this.form = {
        id: data.id,
        kategori_id: data.kategori_id,
        nama: data.nama,
        judul: data.judul,
        file: data.file,
      };
    },

    mdl_exit: function () {
      this.nama = "";
      this.judul = "";
      this.kategori_id = "";
      this.file = "";
      this.file_old = "";
    },

    // ================== PAGINASI ====================
    alertku: function (type, title) {
      this.$swal({
        type: type,
        title: title,
        showConfirmButton: false,
        timer: 800,
      });
    },

    btn_prev: function () {
      if (this.page_first > 1) {
        this.page_first--;
      } else {
        this.page_first = 1;
      }
      this.getView();
    },

    btn_next: function () {
      if (this.page_first >= this.page_last) {
        this.page_first == this.page_last;
      } else {
        this.page_first++;
      }
      this.getView();
    },

    cari_data: function () {
      this.page_first = 1;
      this.getView();
    },
    // ================== PAGINASI ====================
  },
  // =============================================================== METHODS =========================================================================

  mounted() {
    this.getView();
  },

}
</script>
