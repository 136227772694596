<template>
    <div>
    <q-header elevated class="q-py-md q-px-md bg-secondary text-black">
      <q-toolbar>
        <q-toolbar-title> 
          <q-img class="q-ma-sm" src="konsel.png" style="height: auto; max-width: 35px" />
          <span class="text-weight-bold text-uppercase" style="font-size:25px">
            E-RIDA
          </span>
        </q-toolbar-title> 

        <div v-if="$q.screen.sm || $q.screen.md || $q.screen.lg || $q.screen.xl"> 
          <q-tabs v-model="tab" inline-label class="text-black "  >
            <q-route-tab class="text-black " name="Beranda" label="Beranda" to="/" /> 
            <q-route-tab class="text-black " name="Artikel Ilmiah" label="Artikel Ilmiah" to="/artikel" /> 
            <q-route-tab class="text-black " name="Inovasi & Teknologi" label="Inovasi & Teknologi" to="/inotek" /> 
            <q-route-tab class="text-black " name="Publikasi" label="Publikasi" to="/publikasi" /> 
            <q-route-tab class="text-black " name="Berita" label="Berita" to="/berita" /> 
            <q-route-tab class="text-black " name="Tentang" label="Tentang" to="/tentang" />
            
            <!-- <q-route-tab class="text-black " name="Beranda" label="Beranda" to="/" />  -->
          </q-tabs>          
        </div>
        

        <q-btn v-if="$q.screen.lt.sm" dense flat round icon="menu" @click="rightDrawerOpen = !rightDrawerOpen"  />
      </q-toolbar>
    </q-header>

    <q-drawer v-model="rightDrawerOpen" side="right" overlay behavior="mobile" elevated>
        <q-scroll-area class="fit"> 
          
              <q-list>
                <q-item to="/" clickable >
                  <q-item-section>Beranda</q-item-section>
                </q-item>
                <q-item to="/artikel" clickable >
                  <q-item-section>Artikel Ilmiah</q-item-section>
                </q-item>
                <q-item to="/inotek" clickable >
                  <q-item-section>Inovasi & Teknologi</q-item-section>
                </q-item>
                <q-item to="/publikasi" clickable >
                  <q-item-section>Publikasi</q-item-section>
                </q-item>
                <q-item to="/berita" clickable >
                  <q-item-section>Berita</q-item-section>
                </q-item>
                <q-item to="/tentang" clickable >
                  <q-item-section>Tentang</q-item-section>
                </q-item>
              </q-list>    

 
        </q-scroll-area>      
    </q-drawer>        
    </div>
</template>

<script>
 
export default {
    name: "headernya",
    
//   setup () {
//     const rightDrawerOpen = ref(false)

//     return {
//       rightDrawerOpen,
//       toggleRightDrawer () {
//         rightDrawerOpen.value = !rightDrawerOpen.value
//       }
//     }
//   },

  data() {
    return {
      rightDrawerOpen:false,
      tab:'Beranda',
      link :'inbox',
    }
  },
  methods: {
 
  },
}
</script>