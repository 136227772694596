import { createRouter, createWebHistory } from 'vue-router'
import Beranda from '../views/Beranda.vue'
import berita from '../views/berita.vue'
import berita_brida from '../views/berita_brida.vue'
import artikel from '../views/artikel.vue'
import publikasi from '../views/publikasi.vue'
import inotek from '../views/inotek.vue'

const routes = [
  {
    path: '/',
    name: 'beranda',
    component: Beranda
  },
  {
    path: '/tentang',
    name: 'tentang',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/tentang.vue')
    }
  },
  {
    path: '/berita',
    name: 'berita',
    component: berita
  },
  {
    path: '/berita_brida/:id',
    name: 'berita_brida',
    component: berita_brida
  },
  {
    path: '/artikel',
    name: 'artikel',
    component: artikel
  },
  {
    path: '/publikasi',
    name: 'publikasi',
    component: publikasi
  },
  {
    path: '/inotek',
    name: 'inotek',
    component: inotek
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
