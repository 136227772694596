<template>
 
  <div class="">
    <!-- ======================== Sambutan ========================  -->
    <div class="q-my-xxs q-py-xl q-px-xl bg-gray-3" v-if="$q.screen.lt.sm">
      <div class="row justify-between">
      <div class="text-h6 text-weight-bolder text-black">Berita Riset & Inovasi</div>
      <div class="text-h6" style="font-size: 12px;">Beranda / <span class="text-secondary">Berita</span></div>
      </div>
    </div>

    <div class="q-my-xxs q-py-xl q-px-xl text-center bg-grey-3" v-if="$q.screen.sm || $q.screen.md || $q.screen.lg || $q.screen.xl">
      <div class="row justify-between">
      <div class="text-h6 text-weight-bolder text-black">Berita Riset & Inovasi</div>
      <div class="text-h6" style="font-size: 12px;">Beranda / <span class="text-secondary">Berita</span></div>
      </div>
    </div>

    <div class="row wrap justify-between items-start content-start ">
      <div class="col-12 col-md-12 col-sm-12 col-xs-12 q-pa-md q-gutter-y-lg items-end">
        
        <div class="q-pa-lg row items-start q-col-gutter-lg">
          <div class="col-12 col-md-8" >
      <q-card class="my-card" >
      <q-img :src="$store.state.FILE_PATH+berita.foto" width="100%">
      </q-img>

      <q-card-section>
        <div class="text-h6" style="text-align:justify">{{berita.judul}}</div>
      </q-card-section>
      <q-item>
          <q-item-section avatar>
            <q-icon size="45px" color="secondary" name="assignment" />
          </q-item-section>
          <q-item-section>
            <q-item-label>{{berita.createBy}}</q-item-label>
            <q-item-label caption>{{convert_tgl(berita.createAt)}}</q-item-label>
          </q-item-section>
        </q-item>
        <br>

      <q-card-section class="q-pt-none">
        <div v-html="berita.isi" style="text-justify"></div>
      </q-card-section>

    </q-card>
</div>
<div class="col-12 col-md-4" >
      <q-card class="my-card" flat bordered>
      <q-item>
        <q-item-section>
          <div class="text-h5" style="text-align:justify">Berita Lainnya</div>
        </q-item-section>
      </q-item>

      <q-separator />

      <q-card-section horizontal v-for="data in list_data" :key="data.id">
        <q-card-section class="q-pt-xs">
          <br>
          <q-item clickable @click="push_berita(data.id)">

          <q-item-section>
            <q-item-label>{{data.judul}}</q-item-label>
          </q-item-section>
        </q-item>
        </q-card-section>

        <q-card-section class="col-5 flex flex-center">
          <q-img
            class="rounded-borders"
             :src="$store.state.FILE_PATH+data.foto" height="75px"
          />
        </q-card-section>
      </q-card-section>
    </q-card>
</div>
        </div>
      </div>
    </div>

  </div>     
</template>

<script>
export default {
  name: 'berita',
  data() {
         return {
            //  id : this.$route.params.id,
      data_load: false,
      berita : '', 
      form : {
                    id : '',
                    judul : '',
                    sumber : '',
                    deskripsi : '',
                    isi : '',
                    foto : '',
                    createBy : '',
                    createAt : '',
                },
      persistent : false,

      list_data: [],
      page_first: 1,
      page_last: 0,
      cari_value: "",
    };
    },
    methods: {
      getList: function() {
              this.cek_load_data_list = true;
              fetch(this.$store.state.URL_BERITA + "viewSide", {
                  method: "POST",
                  headers: {
                  "content-type": "application/json"
                  },
                  body: JSON.stringify({
                    data_ke: this.page_first,
                    cari_value: this.cari_value
                  })
              })
                  .then(res => res.json())
                  .then(res_data => {
                      this.list_data = res_data.data;
                      this.page_last = res_data.jml_data;
                      this.cek_load_data_list = false;
                      console.log(res_data);
              });
          },
			getView: function() {
                this.cek_load_data = true;
                fetch(this.$store.state.URL_BERITA + "isi_berita", {
                    method: "POST",
                    headers: {
                    "content-type": "application/json",
                    },
                        body: JSON.stringify({
                        id: this.$route.params.id,
                    })
                })
                    .then(res => res.json())
                    .then(res_data => {
                      console.log(res_data);
                        this.berita = res_data[0];
                  this.cek_load_data = false;
                  console.log(berita);
                });
            },
          push_berita : function(id){
                  this.$router.push('/berita_brida/'+id);
                  this.getView();
          },

          to_facebook : function () {
              window.open("http://www.facebook.com/sharer.php?u="+this.HOST, '_blank');
          },
          to_twitter : function(){
              // alert(this.HOST)
              window.open("https://twitter.com/share?url="+this.HOST+"&amp;text="+this.judul, '_blank');
          },


            // ================== PAGINASI ====================
                alertku: function(type, title) {
                this.$swal({
                    type: type,
                    title: title,
                    showConfirmButton: false,
                    timer: 800
                });
                },

                btn_prev : function(){
                        this.cek_load_data = true;
                    if(this.page_first>1){
                        this.page_first--
                    }else{
                        this.page_first = 1;
                    }
                    this.getView();
                },

                btn_next : function(){
                    
                    if(this.page_first >= this.page_last){
                        this.page_first == this.page_last
                    }else{
                        this.page_first++;
                    }
                    this.getView();
                },

                cari_data : function(){
                    this.page_first = 1;
                    this.getView();
                },
                // onFileSelected: function(event) {
                // this.form.file = event.target.files[0];
                // },
                convert_tgl: function(dateString) {
                    var date = new Date(dateString);
                    var getBulan = date.getMonth() + 1; var bulan = '';
                    if (getBulan == '1') {bulan = 'Januari'} 
                    else if(getBulan == '2') {bulan = 'Februari'}
                    else if(getBulan == '3') {bulan = 'Maret'}
                    else if(getBulan == '4') {bulan = 'April'}
                    else if(getBulan == '5') {bulan = 'Mei'}
                    else if(getBulan == '6') {bulan = 'Juni'}
                    else if(getBulan == '7') {bulan = 'Juli'}
                    else if(getBulan == '8') {bulan = 'Agustus'}
                    else if(getBulan == '9') {bulan = 'September'}
                    else if(getBulan == '10') {bulan = 'Oktober'}
                    else if(getBulan == '11') {bulan = 'November'}
                    else if(getBulan == '12') {bulan = 'Desember'}



                    return date.getDate() + " " + bulan + " " + date.getFullYear();
                    // return bulan+ " " + date.getDate() + ". " + date.getFullYear();
                }
		},
		mounted() {
            this.getView();
            this.getList();

		},

}
</script>
