import { createStore } from 'vuex'

var URL = 'https://server-web.konaweselatankab.go.id/'

var API = 'https://server-elitbang.konaweselatankab.go.id/'

export default createStore({
  state: {
    URL_SLIDE                                 : URL + 'api/v1/publish_balitbang_slider/',
    FILE_LOAD                                 : URL + 'uploads/',
    URL                                       : API,
    URL_BERANDA                               : API + 'api/v1/publish_beranda/',
    URL_ARTIKEL                               : API + 'api/v1/publish_artikel/',
    URL_PUBLIKASI                             : API + 'api/v1/publish_publikasi/',
    URL_INOTEK                                : API + 'api/v1/publish_sida/',
    URL_PANDUAN                                : API + 'api/v1/publish_panduan/',
    URL_TENTANG                               : API + 'api/v1/publish_profil/',
    URL_BERITA                                : API + 'api/v1/web_publish_berita/',
    FILE_PATH                                 : API + 'uploads/',

  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
