<template>
 
  <div class="">
    <!-- ======================== SLIDER SIZE ANDROID ========================  -->
 
    <q-responsive  v-if="$q.screen.lt.sm" :ratio="16/9" style="width: 500px; max-width: 100%;">
      <q-carousel swipeable animated :autoplay="true" arrows v-model="slide" infinite >
        <q-carousel-slide v-for="n in this.list_slide" :key="n.index" :name="n.id" :img-src="$store.state.FILE_LOAD+n.file">
          <div class="absolute-bottom" style="text-align: center; padding: 12px; color: white; background-color: rgba(0, 0, 0, .3)">
          <div class="text-h6 text-weight-bolder" style="font-family:garamond; text-shadow: 2px 2px 4px teal;">Elektronik Riset & Inovasi Daerah</div>
          <br>
          <q-btn href="https://admin-erida.konaweselatankab.go.id" color="teal">
      <div>Izin Penelitian</div>
    </q-btn>
        </div>
        </q-carousel-slide>
      </q-carousel>
    </q-responsive>
    
    <!-- ======================== SLIDER SIZE ANDROID ========================  -->

    <!-- ======================== SLIDER SIZE PC ========================  -->
  
      <q-carousel v-if="$q.screen.sm || $q.screen.md || $q.screen.lg || $q.screen.xl" class="q-mt-xxs" swipeable animated :autoplay="true" arrows v-model="slide" height="555px" infinite >
        <q-carousel-slide v-for="n in this.list_slide" :key="n.index" :name="n.id" :img-src="$store.state.FILE_LOAD+n.file">
          <div class="absolute-bottom" style="text-align: center; padding: 12px; color: white; background-color: rgba(0, 0, 0, .3)">
          <div class="text-h3 text-weight-bolder" style="font-family:garamond; text-shadow: 2px 2px 4px teal;">Elektronik Riset & Inovasi Daerah</div>
          <br>
          <q-btn href="https://admin-erida.konaweselatankab.go.id" color="teal">
      <div>Izin Penelitian</div>
    </q-btn>
        </div>
        </q-carousel-slide>
      </q-carousel>    
 
    <!-- ======================== SLIDER SIZE PC ========================  -->

    <!-- ======================== Sambutan ========================  -->
    <br><br><br>
    <center>
    </center>
    <br>
    <div class="q-my-xxs q-py-lg q-px-xxs text-center bg-white text-black" v-if="$q.screen.lt.sm">
      <div class="text-h6 text-weight-bolder">ALUR USULAN PENELITIAN</div>
    </div>
    <br>
    <div class="q-my-xxs q-py-lg q-px-xxs text-center bg-white text-black" v-if="$q.screen.sm || $q.screen.md || $q.screen.lg || $q.screen.xl">
      <div class="text-h4 text-weight-bolder">ALUR USULAN PENELITIAN</div>
    </div>
    <div class="q-pa-xl row q-col-gutter-lg">
      <div class="col-12 col-md-4">
        <div style="box-shadow: 5px 5px 2px teal;">
        
        <q-card flat bordered class="my-card bg-secondary text-center text-white" >

      <q-card-section>
        <div class="text-h1"><span class="material-icons-outlined">
how_to_reg
</span></div>
        <div class="text-h6">Register Akun</div>
        <br>
        <q-separator dark inset />

      <q-card-section>
        Pertama anda harus mendaftarkan akun untuk masuk ke sistem E-litbang sebagai pengguna.
      </q-card-section>
      </q-card-section>

      

      <!-- <q-card-section>
        
      </q-card-section> -->
    </q-card>
      </div>
      </div>
      <div class="col-12 col-md-4">
        <div style="box-shadow: 5px 5px 2px teal;">
        <q-card flat bordered class="my-card bg-secondary text-center text-white">
      <q-card-section>
        <div class="text-h1"><span class="material-icons-outlined">
description
</span></div>
        <div class="text-h6" text-color>Input Form Usulan Penelitian</div>
        <br>
        <q-separator dark inset />

      <q-card-section>
        Kedua silahkan pilih menu usulan kemudian isi dan upload data yang diperlukan.
      </q-card-section>
      </q-card-section>
    </q-card>
    </div>
      </div>
      <div class="col-12 col-md-4">
        <div style="box-shadow: 5px 5px 2px teal;">
        <q-card flat bordered class="my-card bg-secondary text-center text-white">
      <q-card-section>
        <div class="text-h1"><span class="material-icons-outlined">
fact_check
</span></div>
        <div class="text-h6" text-color>Status Pengusulan</div>
        
<br>
      <q-separator dark inset />

      <q-card-section>
        Ketiga Lihat status pengajuan usulan melalui menu reporting atau email pengusul.
      </q-card-section>
      </q-card-section>
    </q-card>
      </div>
      </div>
      <div class="col-12 col-md-4">
        <div style="box-shadow: 5px 5px 2px teal;">
        <q-card flat bordered class="my-card bg-secondary text-center text-white">
      <q-card-section>
        <div class="text-h1"><span class="material-icons-outlined">
text_snippet
</span></div>
        <div class="text-h6" text-color>Mengisi Survey IKM</div>
        <br>
        <q-separator dark inset />

      <q-card-section>
        Keempat jika status usulan sudah terverifikasi anda akan diminta untuk mengisi survey.
      </q-card-section>
      </q-card-section>
    </q-card>
      </div>
      </div>
      <div class="col-12 col-md-4">
        <div style="box-shadow: 5px 5px 2px teal;">
        <q-card flat bordered class="my-card bg-secondary text-center text-white">
      <q-card-section>
        <div class="text-h1"><span class="material-icons-outlined">
file_download
</span></div>
        <div class="text-h6">Undah Surat Rekomendasi</div>
        <br>
        <q-separator dark inset />

      <q-card-section>
        Kelima yaitu surat rekomendasi hanya bisa diundah setelah pengusul mengisi survey.
      </q-card-section>
      </q-card-section>

    </q-card>
      </div>
      </div>
      <div class="col-12 col-md-4">
        <div style="box-shadow: 5px 5px 2px teal;">
        <q-card flat bordered class="my-card bg-secondary text-center text-white">
      <q-card-section>
        <div class="text-h1"><span class="material-icons-outlined">
file_upload
</span></div>
        <div class="text-h6">Unggah Laporan Akhir</div>
        <br>
        <q-separator dark inset />

      <q-card-section>
        Keenam yaitu laporan akhir perlu diunggah agar penelitian dapat dipublish pada aplikasi Erida.
      </q-card-section>
      </q-card-section>
      
    </q-card>
      </div>
      </div>
    <!-- </div> -->
    </div>
    <br>
    <br>
    <div class="q-pa-xxs q-gutter-lg">
    <div class="row justify-between">

    <q-parallax
      src="img/bg.jpg" :height="700" :speed="1"
    >
    <div class="q-pa-xl row q-col-gutter-lg">
    <div class="col-6 col-md-3 q-pa-xl">
    <div class="q-pa-lg q-gutter-x-xxs q-gutter-y-lg text-center">
      <img src="svg/lightbulb_white_24dp.svg" style="width: 75px; height: 75px;">
          <div class="text-h3 text-white text-center" style="text-shadow: 2px 2px teal;">{{jumlah_penelitian}}</div>
          <div class="text-h6 text-grey-3 text-center">
            Penelitian
          </div>
      </div>
    </div>
      <div class="col-6 col-md-3 q-pa-xl">
        <div class="q-pa-lg q-gutter-x-xxs q-gutter-y-lg text-center">
      <img src="svg/inventory_white_24dp.svg" style="width: 75px; height: 75px">
          <div class="text-h3 text-white text-center" style="text-shadow: 2px 2px teal;">{{jumlah_pengabdian}}</div>
          <div class="text-h6 text-grey-3 text-center">
            Pengabdian
          </div>
        </div>
      </div>
      <div class="col-6 col-md-3 q-pa-xl">
        <div class="q-pa-lg q-gutter-x-xxs q-gutter-y-lg text-center">
      <img src="svg/description_white_24dp.svg" style="width: 75px; height: 75px;">
          <div class="text-h3 text-white text-center" style="text-shadow: 2px 2px teal;">{{jumlah_artikel}}</div>
          <div class="text-h6 text-grey-3 text-center">
            Artikel Ilmiah
          </div>
        </div>
      </div>
      <div class="col-6 col-md-3 q-pa-xl">
        <div class="q-pa-lg q-gutter-x-xxs q-gutter-y-lg text-center">
      <img src="svg/info_white_24dp.svg" style="width: 75px; height: 75px">
          <div class="text-h3 text-white text-center" style="text-shadow: 2px 2px teal;">{{jumlah_sida}}</div>
          <div class="text-h6 text-grey-3 text-center">
            Inotek
          </div>
      </div>
      </div>
    </div>
    </q-parallax>

    </div>
  </div>
  <div class="q-pa-xl row q-col-gutter-lg bg-teal-1">
    <div class="col-12 col-md-1">
    </div>
      <div class="col-12 col-md-7">
        <br>
        <div class="text-h4 text-weight-bolder" style="font-family:garamond; text-shadow: 2px 2px 4px teal;">INOVASI & TEKNOLOGI DAERAH</div>
        <br>
        <br>
        <span class="" style="font-size: 17px; line-height: 2; ">
              Inovasi & Teknologi Daerah yang diselenggarakan Badan Riset dan
              Inovasi Daerah Kabupaten Konawe Selatan, INOTEK adalah bentuk
              apresiasi Bupati Konawe Selatan kepada para inovator yang
              memajukan daerah produk inovasi dan teknologi untuk masyarakat luas.
            </span>
            <br>
            <br>
            <br>
            <q-btn to="/inotek" color="teal">
      <div>LIHAT SELENGKAPNYA</div>
    </q-btn>
      </div>
      <div class="col-12 col-md-4">
        <img src="img/inotek.png" style="width: 300px">
      </div>
  </div>

    <!-- ======================== BERITA ========================  -->
    <br>
    <br>
    <div class="q-my-md q-py-lg q-px-md text-center bg-secondary-6 text-black" v-if="$q.screen.lt.sm">
      <div class="text-h6 text-weight-bolder">BERITA RISET & INOVASI DAERAH</div>
    </div>
    <div class="q-my-md q-py-lg q-px-md text-center bg-secondary-6 text-black" v-if="$q.screen.sm || $q.screen.md || $q.screen.lg || $q.screen.xl">
      <div class="text-h6 text-weight-bolder">BERITA RISET & INOVASI DAERAH</div>
    </div>
    
    
    <div class="q-pa-xl row items-start q-col-gutter-xl">
<div class="col-12 col-md-4" v-for="data in list_berita" :key="data.id">
      <q-card class="my-card" >
      <q-img :src="$store.state.FILE_PATH+data.foto" height="350px">
      <div class="absolute-bottom">
        <div class="text-h6 text-justify">{{data.judul}}</div>
        
      </div>
      </q-img>

      <q-card-section>
        <q-item>
          <q-item-section avatar>
            <q-icon color="secondary" name="assignment" />
          </q-item-section>

          <q-item-section>
            <q-item-label>{{data.createBy}}</q-item-label>
            <q-item-label caption>{{convert_tgl(data.createAt)}}</q-item-label>
          </q-item-section>
        </q-item>
        <!-- <div class="text-h6">{{data.judul}}</div>
        <div class="text-subtitle2">{{data.createBy}}</div> -->
        <!-- <div v-html="data.deskripsi" class="text-justify"></div> -->
      </q-card-section>

      <q-card-actions>
        <q-btn flat  @click="push_berita(data.id)" style="color: #26A69A" label="Baca Selengkapnya" />
      </q-card-actions>
    </q-card>
</div>
    </div>
      
         

          <!-- <q-card v-for="n in list_berita " :key="n.index" class="my-card shadow-2" flat bordered clickable >


            <q-card-section class="shadow-2" v-if="$q.screen.lt.sm">
              <q-img class="" :src="$store.state.FILE_LOAD+n.file" />
              <q-card-section class="">
                <div class="text-label text-blue-grey" style="font-size:11px">       
                  <q-icon  :size="'xs'" name="mdi-calendar-text" /> {{convert_tgl(n.createAt)}} <br>
                  <q-icon  :size="'xs'" name="mdi-pencil" /> {{n.createBy}} 
                </div>
                <div class="text-uppercase" style="font-size:15px;"><b>{{n.judul}}</b></div>
                <div style="font-size:13px;">{{n.deskripsi}}</div>
                <div><a href="javascript:void(0)" @click="push_berita(n.id)" class="text-hampa text-blue-grey" style="font-size:11px"><i>Selengkapnya</i></a></div>
              </q-card-section> 
            </q-card-section>

            <q-card-section class="shadow-2" horizontal v-if="$q.screen.sm || $q.screen.md || $q.screen.lg || $q.screen.xl">
              <q-img class="" :src="$store.state.FILE_LOAD+n.foto" style="max-width: 200px" />
              <q-card-section class="">
                <div class="text-label text-blue-grey" style="font-size:11px">       
                  <q-icon  :size="'xs'" name="mdi-calendar-text" /> {{convert_tgl(n.createAt)}} <br>
                  <q-icon  :size="'xs'" name="mdi-pencil" /> {{n.createBy}} 
                </div>
                <div class="text-uppercase" style="font-size:15px;"><b>{{n.judul}}</b></div>
                <div style="font-size:13px;">{{n.deskripsi}}</div>
                <div><a href="javascript:void(0)" @click="push_berita(n.id)" class="text-hampa text-blue-grey" style="font-size:11px"><i>Selengkapnya</i></a></div>
              </q-card-section>


            </q-card-section>
          </q-card> -->

  </div>     
</template>

<script>
export default {
  name: 'Beranda',
  data() {
    return {
      list_slide:[],
      jumlah_penelitian : '',
      jumlah_pengabdian : '',
      jumlah_sida : '',
      jumlah_artikel : '',
      list_berita:[],
      form : {
                id : '',
                judul : '',
                sumber : '',
                deskripsi : '',
                isi : '',
                foto : '',
                createBy : '',
                createAt : '',
            },

      slide: 1,

    }
  },
  methods: {
    slides: function() {
        this.cek_load_data = true;
      fetch(this.$store.state.URL_SLIDE, {
        method: "GET",
 
 
      })
        .then(res => res.json())
        .then(res_data => {
            this.list_slide = res_data;  
        });
    },
    getBerita: function() {
                this.cek_load_data = true;
                fetch(this.$store.state.URL_BERITA + "viewPage", {
                    method: "POST",
                    headers: {
                    "content-type": "application/json",
                    },
                        body: JSON.stringify({
                        data_ke: 1
                    })
                })
                    .then(res => res.json())
                    .then(res_data => {
                        this.list_berita = res_data.data;
                        this.page_last = res_data.jml_data;
                        this.cek_load_data = false;
                        console.log(res_data);
                });
            },
            push_berita : function(id){
                this.$router.push('/berita_brida/'+id);
            },
    getJumlahPenelitian : function(){
        fetch(this.$store.state.URL_BERANDA + "penelitian", {
            method: "POST",
            // headers: {
            //   "content-type": "application/json",
            //   authorization: "kikensbatara " + localStorage.token
            // },
        })
            .then(res => res.json())
            .then(res_data => {
              // console.log(res_data);
              this.jumlah_penelitian = res_data.data[0].jumlah_penelitian;
              // console.log(jumlah_pelaku);
        });
      },

      getJumlahSida : function(){
        fetch(this.$store.state.URL_BERANDA + "sida", {
            method: "POST",
            // headers: {
            //   "content-type": "application/json",
            //   authorization: "kikensbatara " + localStorage.token
            // },
        })
            .then(res => res.json())
            .then(res_data => {
              // console.log(res_data);
              this.jumlah_sida = res_data.data[0].jumlah_sida;
              // console.log(jumlah_pelaku);
        });
      },

      getJumlahArtikel : function(){
        fetch(this.$store.state.URL_BERANDA + "artikel", {
            method: "POST",
            // headers: {
            //   "content-type": "application/json",
            //   authorization: "kikensbatara " + localStorage.token
            // },
        })
            .then(res => res.json())
            .then(res_data => {
              // console.log(res_data);
              this.jumlah_artikel = res_data.data[0].jumlah_artikel;
              // console.log(jumlah_pelaku);
        });
      },

      getJumlahPengabdian : function(){
        fetch(this.$store.state.URL_BERANDA + "pengabdian", {
            method: "POST",
            // headers: {
            //   "content-type": "application/json",
            //   authorization: "kikensbatara " + localStorage.token
            // },
        })
            .then(res => res.json())
            .then(res_data => {
              // console.log(res_data);
              this.jumlah_pengabdian = res_data.data[0].jumlah_pengabdian;
              // console.log(jumlah_pelaku);
        });
      },
      convert_tgl: function(dateString) {
                    var date = new Date(dateString);
                    var getBulan = date.getMonth() + 1; var bulan = '';
                    if (getBulan == '1') {bulan = 'Januari'} 
                    else if(getBulan == '2') {bulan = 'Februari'}
                    else if(getBulan == '3') {bulan = 'Maret'}
                    else if(getBulan == '4') {bulan = 'April'}
                    else if(getBulan == '5') {bulan = 'Mei'}
                    else if(getBulan == '6') {bulan = 'Juni'}
                    else if(getBulan == '7') {bulan = 'Juli'}
                    else if(getBulan == '8') {bulan = 'Agustus'}
                    else if(getBulan == '9') {bulan = 'September'}
                    else if(getBulan == '10') {bulan = 'Oktober'}
                    else if(getBulan == '11') {bulan = 'November'}
                    else if(getBulan == '12') {bulan = 'Desember'}



                    return date.getDate() + " " + bulan + " " + date.getFullYear();
                    // return bulan+ " " + date.getDate() + ". " + date.getFullYear();
                }
  },
  mounted() {
    this.getBerita()
    this.slides()
    this.getJumlahPenelitian()
    this.getJumlahPengabdian()
    this.getJumlahSida()
    this.getJumlahArtikel()
  },
}
</script>
