<template>
 
  <div class="">
    <!-- ======================== Sambutan ========================  -->
    <div class="q-my-xxs q-py-xl q-px-xl bg-gray-3" v-if="$q.screen.lt.sm">
      <div class="row justify-between">
      <div class="text-h6 text-weight-bolder text-black">Berita Riset & Inovasi</div>
      <div class="text-h6" style="font-size: 12px;">Beranda / <span class="text-secondary">Berita</span></div>
      </div>
    </div>

    <div class="q-my-xxs q-py-xl q-px-xl text-center bg-grey-3" v-if="$q.screen.sm || $q.screen.md || $q.screen.lg || $q.screen.xl">
      <div class="row justify-between">
      <div class="text-h6 text-weight-bolder text-black">Berita Riset & Inovasi</div>
      <div class="text-h6" style="font-size: 12px;">Beranda / <span class="text-secondary">Berita</span></div>
      </div>
    </div>

    <div class="row wrap justify-between items-start content-start ">
      <div class="col-12 col-md-12 col-sm-12 col-xs-12 q-pa-md q-gutter-y-lg items-end">
            <div class="col-12 q-pa-lg">
                <q-input standout="bg-secondary text-black" bottom-slots v-model="cari_value" @keyup="cari_data()" label="Pencarian" style="width:100%" >
                    <template v-slot:prepend>
                        <q-icon color="black" name="search" />
                    </template>
                </q-input>
            </div>
        
        <div class="q-pa-lg row items-start q-col-gutter-lg">
          <div class="col-12 col-md-4" v-for="data in list_data" :key="data.id">
      <q-card class="my-card" height="500px" >
      <q-img :src="$store.state.FILE_PATH+data.foto" height="400px">
      <div class="absolute-bottom">
        <div class="text-h6" style="text-align:justify">{{data.judul}}</div>
        <div class="row justify-between">
        <div class="text-subtitle2">{{data.createBy}}</div>
        <div class="text-subtitle2">{{convert_tgl(data.createAt)}}</div>
        </div>
        
      </div>
      </q-img>

      <q-card-section>
        <!-- <div class="text-h6">{{data.judul}}</div>
        <div class="text-subtitle2">{{data.createBy}}</div> -->
        <div v-html="data.deskripsi" style="text-align:justify"></div>
      </q-card-section>

      <q-card-actions>
        <q-btn flat  @click="push_berita(data.id)" style="color: #26A69A" label="Baca Selengkapnya" />
      </q-card-actions>
    </q-card>
</div>
        </div>

          <div class="q-pa-lg flex flex-center">
            <q-btn @click="btn_prev" color="secondary" icon="mdi-skip-previous" />
            &nbsp;
            <span style="font-size: 85%; color: #a5a5a5">
                <b>{{page_first}} - {{page_last}}</b>
            </span>
            &nbsp;
            <q-btn @click="btn_next" color="secondary" icon="mdi-skip-next" />   
          </div>
      </div>
    </div>
    <q-dialog v-model="persistent" persistent full-width transition-show="scale" transition-hide="scale">
      <q-card class="text-white">
        <q-card-section class="bg-teal">
          <div class="text-h6">Lampiran</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
                        <div class="text-center">
                                        <embed :src="$store.state.FILE_PATH+form.file" width="1250" height="500" type='application/pdf'>
                                    </div>
        </q-card-section>

        <q-card-actions align="right" class="bg-white text-teal">
          <q-btn flat label="Tutup" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>

  </div>     
</template>

<script>
export default {
  name: 'berita',
  data() {
         return {
      data_load: false,
      form : {
                    id : '',
                    judul : '',
                    sumber : '',
                    deskripsi : '',
                    isi : '',
                    foto : '',
                    createBy : '',
                    createAt : '',
                },
      persistent : false,

      list_data: [],
      page_first: 1,
      page_last: 0,
      cari_value: "",
    };
    },
    methods: {
			getView: function() {
                this.cek_load_data = true;
                fetch(this.$store.state.URL_BERITA + "view", {
                    method: "POST",
                    headers: {
                    "content-type": "application/json",
                    },
                        body: JSON.stringify({
                        data_ke: this.page_first,
                        cari_value: this.cari_value
                    })
                })
                    .then(res => res.json())
                    .then(res_data => {
                        this.list_data = res_data.data;
                        this.page_last = res_data.jml_data;
                        this.cek_load_data = false;
                        console.log(res_data);
                });
            },
            push_berita : function(id){
            this.$router.push('/berita_brida/'+id);
            },


            // ================== PAGINASI ====================
                alertku: function(type, title) {
                this.$swal({
                    type: type,
                    title: title,
                    showConfirmButton: false,
                    timer: 800
                });
                },

                btn_prev : function(){
                        this.cek_load_data = true;
                    if(this.page_first>1){
                        this.page_first--
                    }else{
                        this.page_first = 1;
                    }
                    this.getView();
                },

                btn_next : function(){
                    
                    if(this.page_first >= this.page_last){
                        this.page_first == this.page_last
                    }else{
                        this.page_first++;
                    }
                    this.getView();
                },

                cari_data : function(){
                    this.page_first = 1;
                    this.getView();
                },
                // onFileSelected: function(event) {
                // this.form.file = event.target.files[0];
                // },
                convert_tgl: function(dateString) {
                    var date = new Date(dateString);
                    var getBulan = date.getMonth() + 1; var bulan = '';
                    if (getBulan == '1') {bulan = 'Januari'} 
                    else if(getBulan == '2') {bulan = 'Februari'}
                    else if(getBulan == '3') {bulan = 'Maret'}
                    else if(getBulan == '4') {bulan = 'April'}
                    else if(getBulan == '5') {bulan = 'Mei'}
                    else if(getBulan == '6') {bulan = 'Juni'}
                    else if(getBulan == '7') {bulan = 'Juli'}
                    else if(getBulan == '8') {bulan = 'Agustus'}
                    else if(getBulan == '9') {bulan = 'September'}
                    else if(getBulan == '10') {bulan = 'Oktober'}
                    else if(getBulan == '11') {bulan = 'November'}
                    else if(getBulan == '12') {bulan = 'Desember'}



                    return date.getDate() + " " + bulan + " " + date.getFullYear();
                    // return bulan+ " " + date.getDate() + ". " + date.getFullYear();
                }
		},
		mounted() {
            this.getView();

		},

}
</script>
